<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{ name: page_route }"
          />
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <BtnCircle
            v-if="login.permissions.insurances.create"
            tLabel="Editar"
            tPosition="bottom"
            icon="mdi-pencil"
            color="info"
            size="x-small"
            :to="{ name: `${page_route}.update`, params: { id: id } }"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!loading">
      <v-row>
        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'GENERAL'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col cols="6" xs="6" md="6">
                  <ViewData label="Nombre" :value="data.name" />
                </v-col>
                <v-col cols="6" xs="6" md="6" class="text-right">
                  <img
                    v-if="data.logo"
                    height="70"
                    :src="url_documents + '/insurances/' + data.logo"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'DOMICILIO'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col cols="12" xs="12" md="6">
                  <ViewData label="Calle" :value="data.street" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Número exterior"
                    :value="data.external_number"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Número interior"
                    :value="data.internal_number"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Colonia" :value="data.suburb" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Estado" :value="data.entity" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Municipio" :value="data.municipality" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Código postal" :value="data.zip" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'LEGAL'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="Razón social" :value="data.legal_name" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="RFC" :value="data.code_tax" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Domicilio fiscal"
                    :value="data.legal_address"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'CONFIGURACIÓN'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col cols="12" xs="12" md="2">
                  <ViewData
                    label="Dictamen Sinergia"
                    :value="data.judgment ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="2">
                  <ViewData label="Fondo" :value="data.fund ? 'SI' : 'NO'" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Descuento" :value="data.discount" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Monto del fondo" :value="data.fund_mount" />
                </v-col>
                <v-col cols="12" xs="12" md="2">
                  <ViewData
                    label="NET ID"
                    :value="data.net_id ? data.net_id : ''"
                    :alert="!data.net_id ? true : false"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="2">
                  <ViewData
                    label="Búsqueda con A. materno"
                    :value="data.find_full_last_name ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="2">
                  <ViewData
                    label="Núm. siniestro auto"
                    :value="data.auto_sinister_number ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="2">
                  <ViewData
                    label="Enviar e-mail cartas ingreso"
                    :value="data.send_email_rha ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <ViewData label="Leyendas" :value="data.caption" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'REDES'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Proveedores"
                    :value="data.provider_group.provider_group"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Médicos"
                    :value="data.doctor_group.doctor_group"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2
                class="text-caption"
                v-text="'CONFIGURACIÓN DE FACTURACIÓN'"
              />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Paciente"
                    :value="data.bill_visible_insured ? 'SÍ' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Hospital"
                    :value="data.bill_visible_provider ? 'SÍ' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Siniestro"
                    :value="data.bill_visible_sinester ? 'SÍ' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Carta"
                    :value="data.bill_visible_letter_auth ? 'SÍ' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Descuento"
                    :value="data.bill_visible_discounts_amount ? 'SÍ' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Deducible"
                    :value="data.bill_visible_deductibles_amount ? 'SÍ' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Coaseguro"
                    :value="data.bill_visible_coinsurances_amount ? 'SÍ' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Mostrar descuento"
                    :value="data.bill_with_discount ? 'SÍ' : 'NO'"
                  />
                </v-col>
                <!-- <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Sumar deducible y coaseguro"
                    :value="data.add_deductible_coinsurance ? 'SÍ' : 'NO'"
                  />
                </v-col> -->
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'FACTURAS'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col
                  cols="12"
                  v-for="(invoice_date, i) in data.invoice_dates"
                  :key="i"
                >
                  <v-row>
                    <v-col cols="12" xs="12" md="6">
                      <ViewData
                        :label="`Día de recepción* (${i + 1})`"
                        :value="invoice_date.day.day"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                      <ViewData label="Hora" :value="invoice_date.hour" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'PAGO'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col
                  cols="12"
                  v-for="(invoice_pay_day, i) in data.invoice_pay_days"
                  :key="i"
                >
                  <v-row>
                    <v-col cols="12" xs="12" md="12">
                      <ViewData
                        :label="`Día* (${i + 1})`"
                        :value="invoice_pay_day.day"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'SERVICIO'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" v-for="(service, i) in data.services" :key="i">
                  <v-row>
                    <v-col cols="12" xs="12" md="12">
                      <ViewData
                        :label="`Tipo* (${i + 1})`"
                        :value="service.service.service_type"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'Descuentos trasladados'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col
                  cols="12"
                  v-for="(insurance_discount, i) in data.insurance_discounts"
                  :key="i"
                >
                  <v-row>
                    <v-col cols="12" xs="12" md="6">
                      <ViewData
                        v-if="insurance_discount.provider_brand"
                        :label="`Concentrador (${i + 1})`"
                        :value="
                          insurance_discount.provider_brand.provider_brand
                        "
                      />
                      <ViewData
                        v-else="insurance_discount.provider_brand"
                        :label="`Concentrador (${i + 1})`"
                        :value="'NINGUNO'"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                      <ViewData
                        :label="`Descuento trasladado`"
                        :value="insurance_discount.discount"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'Zonas'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" v-for="(zone, i) in data.zones" :key="i">
                  <v-row>
                    <v-col cols="12" xs="12" md="6">
                      <ViewData
                        :label="`Zona calle (${i + 1})`"
                        :value="zone.street"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData
                        label="Número exterior"
                        :value="zone.external_number"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData
                        label="Número interior"
                        :value="zone.internal_number"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData label="Colonia" :value="zone.suburb" />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData label="Estado" :value="zone.entity" />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData label="Municipio" :value="zone.municipality" />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData label="Código postal" :value="zone.zip" />
                    </v-col>
                    <v-col cols="12" xs="12" md="12">
                      <ViewData :label="`Hospital`" />
                      <span v-for="(provider, i) in zone.providers" :key="i">
                        {{ provider.trade_name
                        }}{{ i === zone.providers.length - 1 ? "." : ", " }}
                      </span>
                    </v-col>
                    <v-col cols="12">
                      <v-divider />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'REGISTRO'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Creación"
                    :value="!loading && data.created_by.email"
                    :subvalue="data.created_at"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="U. actualización"
                    :value="!loading && data.updated_by.email"
                    :subvalue="data.updated_at"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <BtnCircle
            v-if="login.permissions.insurances.delete"
            tLabel="Desactivar"
            tPosition="top"
            icon="mdi-delete"
            color="error"
            size="x-small"
            :click="HandleDesactivate"
            :loading="loading"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  URL_DOCUMENTS,
} from "../../control";
import { show, destroy } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import ViewData from "../../components/ViewData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    ViewData,
    FaqDlg,
  },
  data() {
    return {
      page_route: "aseguradoras",
      id: this.$attrs.id,
      login: this.$store.getters.getLogin,
      loading: true,
      url_documents: URL_DOCUMENTS,
      data: {
        provider_group: {},
        doctor_group: {},
        created_by: {},
        updated_by: {},
      },
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    HandleDesactivate() {
      this.$swal
        .fire(msgConfirm("Confirma la desactivación del registro?"))
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;

            destroy(
              "insurances",
              this.login.token,
              this.id,
              this.login.id
            ).then((response) => {
              this.$swal.fire(
                msgAlert(
                  response.success ? "success" : "error",
                  response.message
                )
              );

              response.success
                ? this.$router.push({ name: this.page_route })
                : console.log(response.message);
            });
          }
        });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    show("insurances", this.login.token, this.id).then((response) => {
      this.data = response;
      this.loading = false;
    });
  },
};
</script>